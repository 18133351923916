import { ErrorPage } from "../components/ErrorPages";

const NotFound = () => {
  return (
    <>
      <ErrorPage status={404} />
    </>
  );
};

export default NotFound;
